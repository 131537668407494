import React from 'react';
import LinkedIn from '../img/linkedin.webp';
import Email from '../img/email.webp';
import Instagram from '../img/instagram.webp';
import Logo from '../img/favicon.ico';

const Footer = () => {
    return (
      <footer className="footer">
        <div className="icons-container">
          <a href="https://www.linkedin.com/company/dalconsultingassociation/" target="_blank" rel="noopener noreferrer">
            <img className="icons px-5-w" src={LinkedIn} alt="LinkedIn icon" />
          </a>
          <a href="mailto:dca@dal.ca">
            <img className="icons px-5-w" src={Email} alt="Email icon" />
          </a>
          <a href="https://www.instagram.com/dalconsultingassociation/" target="_blank" rel="noopener noreferrer">
            <img className="icons px-5-w" src={Instagram} alt="Instagram icon" />
          </a>
        </div>
        <div className="footer-nav">
          <div className="footer-links">
            <a href="/home#">Home</a>
            <a href="/home#about-us">About</a>
            <a href="/membership">Membership</a>
            <a href="/sponsors">Sponsors</a>
            <a href="/resources">Resources</a>
            <a href="/membership#events">Events</a>
            <a href="/home#contact-us">Contact Us</a>
          </div>
        </div>
        <p className="text-footer text-center">
          Copyright ©2024 Dalhousie Consulting Association
          <img className="footer-logo" src={Logo} alt="Footer DCA handshake logo" />
        </p>
      </footer>
    );
};

export default Footer;
