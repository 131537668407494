import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Home from './Pages/Home/Home';
import Membership from './Pages/Membership/Membership';
import Sponsors from './Pages/Sponsors/Sponsors';
import TeamStructure from './Pages/TeamStructure/TeamStructure';
import Navbar from './Components/header.js';
import Footer from './Components/footer.js';
import Resources from './Pages/Resources/Resources.js';
import "./App.css";

function App() {

  
  // START: Test API call code
  // const [data, setData] = useState(null);

  // const testApiCall = async () => {
  //   try {
  //     const response = await fetch('https://dca-backend-0udw.onrender.com/api/test');
  //     const result = await response.json();
  //     setData(result.message); // Assuming the API returns { "message": "API is working!" }
  //   } catch (error) {
  //     console.error("Error connecting to the back-end:", error);
  //     setData("Error connecting to the back-end");
  //   }
  // };
  // END: Test API call code

  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const location = useLocation(); // useLocation hook inside the Router context
  const isHomePage = location.pathname === '/home' || location.pathname.startsWith('/home#');

  return (
    <>
      <Navbar />
      <Routes>
        {/* Automatically redirect from the root URL to /home# (homepage) */}
        <Route path="/" element={<Navigate to="/home" />} />

        <Route path="/home" element={<Home />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/sponsors" element={<Sponsors />} />
        <Route path="/team-structure" element={<TeamStructure />} />
        <Route path="/resources" element={<Resources />} />
      </Routes>
      {/* Due to an issue with footer loading on the homepage (the footer only loads at the end of the first root element) do not render the footer when on the homepage */}
      {!isHomePage && <Footer />}
    </>
  );
}

export default App;
