import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ResourceItem({ title, content, link }) {
  return (
    <div className="resource-item">
      <h3 className="resource-title">{title}</h3>
      <p className="resource-content">{content}</p>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer" className="resource-link">
          Learn More
        </a>
      )}
    </div>
  );
}

function Resources() {
  const [resources, setResources] = useState([
    {
      id: 1,
      title: "Interview Preparation Tips",
      content: "Learn about common questions, how to structure your answers, and make a great first impression.",
      link: "#",
    },
    {
      id: 2,
      title: "Resume Building Guide",
      content: "Tips on crafting a resume that highlights your skills and experience effectively.",
      link: "#",
    },
    {
      id: 3,
      title: "Networking Strategies",
      content: "Strategies for building a professional network and making valuable connections.",
      link: "#",
    },
  ]);
  const [pageInfo, setPageInfo] = useState({
    title: "Resources",
    subtitle: "Helpful guides and tips",
    description: "Explore our curated resources to help you in your career journey.",
  });

  const API_URL =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_LOCAL;

  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      console.error("localStorage is not available in this environment.");
      return;
    }

    const cachedResources = localStorage.getItem("resources");
    const cachedPageInfo = localStorage.getItem("pageInfo");

    if (cachedResources && cachedPageInfo) {
      setResources(JSON.parse(cachedResources));
      setPageInfo(JSON.parse(cachedPageInfo));
    } else {
      axios
        .get(`${API_URL}/api/resources?populate=*`)
        .then((response) => {
          const data = response.data.data;
          if (data && data.length > 0) {
            const formattedResources = data.map((item, index) => ({
              id: item.id || index,
              title: item.attributes.title || 'Untitled Resource',
              content: item.attributes.content || 'No content available.',
              link: item.attributes.link || null,
            }));
            setResources(formattedResources);
            localStorage.setItem("resources", JSON.stringify(formattedResources));
          }
        })
        .catch((error) => console.error("Error fetching resources:", error));

      fetch(`${API_URL}/api/resource-page?populate=*`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          const pageData = data.data?.attributes || {};
          const formattedPageInfo = {
            title: pageData.title || "Resources",
            subtitle: pageData.subtitle || "Helpful guides and tips",
            description: pageData.description || "Explore our curated resources to help you in your career journey.",
          };
          setPageInfo(formattedPageInfo);
          localStorage.setItem("pageInfo", JSON.stringify(formattedPageInfo));
        })
        .catch((error) => console.error("Error fetching page information:", error));
    }
  }, [API_URL]);

  return (
    <div className="App">
      <div className="resources-container pb-5-w px-10-w">
        <header className="resources-header mt-10-w">
          <header className="header">
            <h1>{pageInfo.title}</h1>
          </header>
          <p className="resources-subtitle">{pageInfo.subtitle}</p>
          <p className="resources-description">{pageInfo.description}</p>
        </header>

        <section className="resources-list">
          {resources.map((resource) => (
            <ResourceItem key={resource.id} {...resource} />
          ))}
        </section>
      </div>
    </div>
  );
}

export default Resources;