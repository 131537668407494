import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../../Components/Modal';
import image1 from '../../img/event1.webp';
import image2 from '../../img/event2.webp';
import image3 from '../../img/event3.webp';
import image4 from '../../img/event4.webp';
import image5 from '../../img/event5.webp';

const staticEvents = [
  {
    id: 1,
    title: 'Event 1: Event Name',
    imgSrc: image1,
    date: 'October 17, 2024',
    location: 'Kenneth C. Rowe Management Building',
    description: 'No description available',
  },
  {
    id: 2,
    title: 'Event 2: Event Name',
    imgSrc: image2,
    date: 'September 25, 2024',
    location: 'McCain Arts and Social Sciences Building',
    description: 'No description available',
  },
  {
    id: 3,
    title: 'Event 3: Event Name',
    imgSrc: image3,
    date: 'October 6, 2024',
    location: 'Kenneth C. Rowe Management Building',
    description: 'No description available',
  },
  {
    id: 4,
    title: 'Event 4: Event Name',
    imgSrc: image4,
    date: 'October 20, 2024',
    location: 'Kenneth C. Rowe Management Building',
    description: 'No description available',
  },
  {
    id: 5,
    title: 'Event 5: Event Name',
    imgSrc: image5,
    date: 'November 20, 2024',
    location: 'Sexton IDEA Building',
    description: 'No description available',
  },
];

export function Event({ title, imgSrc, date, time, location, description, isMainEvent, onClick }) {
  return (
    <div 
      className={`event ${isMainEvent ? 'main-event' : ''} cursor-pointer`} 
      onClick={onClick}
    >
      <h3>{title}</h3>
      <img src={imgSrc} alt={`Image of ${title}`} />
      <p><strong>Date:</strong> {date}</p>
      {time && <p><strong>Time:</strong> {time}</p>}
      <p><strong>Location:</strong> {location}</p>
      <p>{description}</p>
    </div>
  );
}

function Membership() {
  const [events, setEvents] = useState(staticEvents);
  const [aboutData, setAboutData] = useState({
    title: "Membership",
    subtitle: "Why become a member?",
    p1: "Text not loaded",
    p2: "Text not loaded",
    p3: "Text not loaded",
    p4: "Text not loaded",
    leaderPhoto: "https://via.placeholder.com/150",
    googleFormLink: "#",
  });
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  // Dynamic API URL based on environment
  const API_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_LOCAL;

  // Fetch Membership Data (Leader Image and About Content)
  useEffect(() => {
    axios
      .get(`${API_URL}/api/membership-pages?populate=*`)
      .then((response) => {
        const data = response.data.data;

        if (data && data[0]) {
          const membership = data[0];

          setAboutData({
            title: membership.title || "Membership",
            subtitle: membership.subtitle || "Why become a member?",
            p1: membership.p1 || "Text not loaded",
            p2: membership.p2 || "Text not loaded",
            p3: membership.p3 || "Text not loaded",
            p4: membership.p4 || "Text not loaded",
            leaderPhoto: membership.leaderPhoto?.url || "https://via.placeholder.com/150",
            googleFormLink: membership.googleFormLink || "#",
          });
        }
      })
      .catch((error) => console.error("Error fetching membership data:", error));
  }, [API_URL]);

  // Fetch Events Data
  useEffect(() => {
    axios
      .get(`${API_URL}/api/events?populate=*`)
      .then((response) => {
        const responseData = response.data.data;

        if (!responseData || responseData.length === 0) return;

        const eventsData = responseData.map((event, index) => {
          const rawImageUrl = event.Image?.formats?.medium?.url;

          const cleanedImageUrl = rawImageUrl
            ? rawImageUrl.replace(/\n/g, "").trim()
            : "https://via.placeholder.com/150";

          return {
            id: event.id || index + 1,
            title: event.Title || "Event: No Title",
            imgSrc: cleanedImageUrl,
            date: event.Date
              ? new Date(event.Date).toLocaleDateString()
              : "No Date",
            time: event.Date
              ? new Date(event.Date).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : null,
            location: event.Location || "No Location",
            description: event.Description || "No Description with more details about the event.",
            order: event.Order || 999,
          };
        });

        setEvents(eventsData.sort((a, b) => a.order - b.order));
      })
      .catch((error) => console.error("Error fetching events:", error));
  }, [API_URL]);

  const openModal = (index) => setModalOpenIndex(index);
  const closeModal = () => setModalOpenIndex(null);

  return (
    <div className="App">
      {/* Membership Section */}
      <section id="membership"></section>
      <div className="columns-container">
        <div className="left-column">
          <img className="leader-photo" src={aboutData.leaderPhoto} alt="Leader" />
        </div>
        <div className="right-column">
          <header className="membership-header">
            <h1>{aboutData.title}</h1>
          </header>
          <p><strong>{aboutData.subtitle}</strong></p>
          <p>{aboutData.p1}</p>
          <p>{aboutData.p2}</p>
          <p>{aboutData.p3}</p>
          <p>{aboutData.p4}</p>
          <div className="join-btn-container">
            <a href={aboutData.googleFormLink} target="_blank" rel="noopener noreferrer">
              <button className="join-us-button">JOIN US</button>
            </a>
          </div>
        </div>
      </div>

      {/* Events Section */}
      <div className="events-section pt-10-h secondary-background" id="events">
        <header className="header">
          <h1>Upcoming Events</h1>
        </header>
        {events.length > 0 && (
          <div className="main-event-section">
            <Event 
              key={events[0].id} 
              {...events[0]} 
              isMainEvent={true} 
              onClick={() => openModal(0)} 
            />
          </div>
        )}
        <section className="gallery mx-5-w text-center pb-5-w">
          <h2>Past Events</h2>
          <div className="gallery-images">
            {events.slice(1).map((event, index) => (
              <Event 
                key={event.id || `event-${index}`} 
                {...event} 
                onClick={() => openModal(index + 1)} 
              />
            ))}
          </div>
        </section>
      </div>

      {/* Modal for Events */}
      {modalOpenIndex !== null && (
        <Modal
          isOpen={modalOpenIndex !== null}
          onClose={closeModal}
          content={events[modalOpenIndex].description}
          image={events[modalOpenIndex].imgSrc}
          altText={`Event: ${events[modalOpenIndex].title}`}
        />
      )}
    </div>
  );
}

export default Membership;
