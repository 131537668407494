import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../../Components/Modal';
import sponsorGold from '../../img/sponsor-gold.webp';
import sponsorSilver from '../../img/sponsor-silver.webp';
import sponsorBronze from '../../img/sponsor-bronze.webp';

function Sponsors() {
  const fallbackData = [
    {
      description: 'No description available',
      image: sponsorGold,
      altText: 'Gold sponsor logo',
      name: 'Gold Sponsor',
    },
    {
      description: 'No description available',
      image: sponsorSilver,
      altText: 'Silver sponsor logo',
      name: 'Silver Sponsor',
    },
    {
      description: 'No description available',
      image: sponsorBronze,
      altText: 'Bronze sponsor logo',
      name: 'Bronze Sponsor',
    },
  ];

  const [sponsorData, setSponsorData] = useState(fallbackData);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  // Dynamic API URL based on environment
  const API_URL =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_LOCAL;

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/sponsors?populate=*`);
        const sponsorsArray = response.data.data;

        if (!sponsorsArray || sponsorsArray.length === 0) {
          console.warn('No sponsors data found.');
          setSponsorData([]);
          return;
        }

        const data = sponsorsArray.map((sponsor) => {
          const imageUrl = sponsor.Image?.url
            ? sponsor.Image.url.replace(/\n/g, '').trim()
            : 'https://via.placeholder.com/150';

          return {
            description: sponsor.Description || 'No description available',
            image: imageUrl,
            altText: sponsor.ImageAlternateText || 'Sponsor Image',
            name: sponsor.Name || 'Sponsor',
          };
        });

        setSponsorData(data); // Update sponsor data state
      } catch (error) {
        console.error('Error fetching sponsors from Strapi:', error);
      }
    };

    fetchSponsors();
  }, [API_URL]);

  const openModal = (index) => setModalOpenIndex(index);
  const closeModal = () => setModalOpenIndex(null);

  return (
    <div className="App">
      <div className="sponsors-main px-10-w mt-10-w">
        <header className="header">
          <h1>Our Sponsors</h1>
        </header>
        <div className="sponsor-container">
          {sponsorData.map((sponsor, index) => (
            <div
              key={index}
              className={`sponsor${index + 1}-container mb-5-w center-items`}
            >
              <div className={`${['gold', 'silver', 'bronze'][index]}-sponsor-label`}>
                {['Gold', 'Silver', 'Bronze'][index]}
              </div>
              <img
                src={sponsor.image || fallbackData[index].image}
                alt={sponsor.altText}
                onClick={() => openModal(index)}
                style={{ cursor: 'pointer' }}
              />
              <div className="sponsor-name">{sponsor.name}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal rendered outside of the sponsor-container */}
      {modalOpenIndex !== null && (
        <Modal
          isOpen={modalOpenIndex !== null}
          onClose={closeModal}
          content={sponsorData[modalOpenIndex].description}
          image={sponsorData[modalOpenIndex].image || fallbackData[modalOpenIndex].image}
          altText={sponsorData[modalOpenIndex].altText}
        />
      )}
    </div>
  );
}

export default Sponsors;
