import React, { useState } from "react";
import '../App.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Message: ''
  });

  const [status, setStatus] = useState("");
  const [statusType, setStatusType] = useState(""); // To track if the status is success or error

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the mailto link
    const subject = encodeURIComponent(`Message from ${formData.Name}`);
    const body = encodeURIComponent(
      `Name: ${formData.Name}\nEmail: ${formData.Email}\n\nMessage:\n${formData.Message}`
    );

    // The recipient email (you can replace this with any email address you want)
    const recipient = "dca@dal.ca";

    // Construct the mailto link
    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

    // Open the email client with the populated data
    window.location.href = mailtoLink;

    // Clear form data
    setFormData({
      Name: "",
      Email: "",
      Message: "",
    });

    // Update status and set status type to success
    setStatus("Your email client has been opened with your message.");
    setStatusType("success");
  };

  return (
    <div className="contact-form-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="Name"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Your name"
            required
          />
          <label htmlFor="Name">Name</label>
        </div>

        <div className="form-group">
          <input
            type="email"
            id="Email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            placeholder="Your email"
            required
          />
          <label htmlFor="Email">Email</label>
        </div>

        <div className="form-group">
          <textarea
            id="Message"
            name="Message"
            value={formData.Message}
            onChange={handleChange}
            placeholder="Your message"
            rows="4"
            required
          />
          <label htmlFor="Message">Message</label>
        </div>

        <div className="form-button-container">
          <button type="submit" className="form-button">Send Message</button>
        </div>

        {status && (
          <p className={`status-message ${statusType}`}>
            {status}
          </p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
