import React, { useEffect } from "react";
import axios from "axios";

const Instagram = () => {
  // Uncomment the token and headers section below if you want to use a secure token for local Strapi testing
  // const token =
  //   "adf1470cd67fe8321f1f49cb63d85faf98b5c446bceb787a02f3f39d13cf6260dbdcde32458603cdf0fab936798a76ece8dbca7253481e24d1533982ce580b653f50d87981afa8c46155c937dbf45fb6db22634e4011e461f2a8923d9867cf3096d1cb482c464a92d8f835f133018315320adf16ffdcf9d86c1aaa0668129c9a";

  // Dynamic API URL based on environment
  const API_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_LOCAL;

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/contacts`, {
          // headers: {
          //   Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          //   "Content-Type": "application/json",
          // },
        });
        const data = response.data.data[0];
        console.log("Fetched data:", data);
      } catch (error) {
        console.error("Error retrieving from Strapi backend:", error);
      }
    };

    fetchContactInfo();
  }, [API_URL]);


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//www.instagram.com/embed.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Array of Instagram embed links
  const instagramLinks = [
    "https://www.instagram.com/p/CztaKF0pFCD/",
    "https://www.instagram.com/p/DBPb_oJpY-G/",
  ];

  return (
    <div className="App">
      <div className="insta-container">
        <section className="pt-10-h" id="instagram"></section>
        <header className="header">
          <h1>Instagram</h1>
        </header>

        {/* Flex container for Instagram posts */}
        <div className="instagram-feed-container pb-5-w">
          {instagramLinks.map((link, index) => (
            <div className="instagram-embed" key={index}>
              <div className="instagram-embed-container">
                <blockquote
                  className="instagram-media"
                  data-instgrm-captioned
                  data-instgrm-permalink={link}
                  data-instgrm-version="14"
                  style={{
                    background: "#FFF",
                    border: 0,
                    borderRadius: "3px",
                    boxShadow:
                      "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
                    margin: "1px",
                    maxWidth: "540px",
                    minWidth: "326px",
                    padding: "0",
                    width: "calc(100% - 2px)",
                  }}
                ></blockquote>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Instagram;
