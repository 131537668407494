

import React, { useEffect, useState } from "react";
import TeamStructure from "../TeamStructure/TeamStructure";

function About() {
  const [aboutData, setAboutData] = useState(null); // State to store the fetched data
  const [imageData, setImageData] = useState(""); // State to store the image URL

  // Dynamic API URL based on environment
  const API_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_LOCAL;

  useEffect(() => {
    // Fetch data from the API
    fetch(`${API_URL}/api/about-pages?populate=*`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched data:", data); // Debug the response

        // Store the fetched data in state
        setAboutData(data);

        // Safely construct the image URL
        const imageUrl = data?.data[0]?.image?.url
          ? data.data[0].image.url.replace(/\n/g, "").trim() // Clean up the URL
          : "https://via.placeholder.com/150"; // Fallback for missing images

        setImageData(imageUrl);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [API_URL]);

  

  return (
    <div className="App">
      <div className="about-div">
        <section className="pt-10-h" id="about-us"></section>
        <div className="about-container px-10-w py-5-w">
          <div className="about-header-container">
            <header className="header">
              <h1>About Us</h1>
            </header>
            <p>
              {aboutData?.data[0]?.p1 ||
                "Example text - not loaded using local voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
            </p>
          </div>
          <div className="about-image-container">
            <img
              src={
                imageData ||
                "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
              }
              alt="Descriptive Alt Text"
            />
          </div>
        </div>
      </div>
      {/* Add the TeamStructure component below */}
      <TeamStructure />
    </div>
  );
}

export default About;
