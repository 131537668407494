import React, { useEffect } from "react";
import homeImage from '../../img/home-image.webp';
import About from '../About Us/About.js';
import Instagram from '../Instagram/Instagram.js';
import ContactUs from '../ContactUs/Contact.js';
import Footer from '../../Components/footer.js';

function Home() {
  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #contact-us)
    const hash = window.location.hash;
    if (hash) {
      // Wait for the page to fully load
      setTimeout(() => {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            // Align the target to the top of the viewport
            block: "start",
          });
        }
      }, 200); // Allow 200ms for the page to render
    }
  }, []);

  return (
    <div className="home-page">
      <div className="home-image-container">
        <img src={homeImage} alt="consultants" className="home-image" />
        <div className="home-image-overlay"></div>
      </div>
      <h1 className="slogan px-10-w text-center">
        Bridging the gap between students and consulting world by growing the consulting community and providing industry knowledge.
      </h1>
      <About />
      <Instagram />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;


