import React from "react";

const Modal = ({ isOpen, onClose, content, image, altText }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        {image && (
          <img src={image} alt={altText} className="modal-image" />
        )}
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Modal;