import React, { useEffect, useState } from "react";
import axios from "axios";
import ContactForm from '../../Components/ContactForm';
import { FaPhone, FaEnvelope, FaUser } from 'react-icons/fa';

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    phone: "(902) 494-1811",
    email: "asiabernabo@dal.ca",
    executive: "Asia Bernabo"

    // Default contact info in case Strapi backend is unreachable
  });

  /// Token for authentication
// Uncomment the line below if you want to use the token for local Strapi testing
// const token =
//   "adf1470cd67fe8321f1f49cb63d85faf98b5c446bceb787a02f3f39d13cf6260dbdcde32458603cdf0fab936798a76ece8dbca7253481e24d1533982ce580b653f50d87981afa8c46155c937dbf45fb6db22634e4011e461f2a8923d9867cf3096d1cb482c464a92d8f835f133018315320adf16ffdcf9d86c1aaa0668129c9a";

// Dynamic API URL based on environment
const API_URL =
process.env.NODE_ENV === "production"
  ? process.env.REACT_APP_API_URL_PRODUCTION
  : process.env.REACT_APP_API_URL_LOCAL;

// Fetch data from Strapi
useEffect(() => {
const fetchContactInfo = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/contacts`, {
      // Uncomment the headers below if you want to use token-based authentication
      // headers: {
      //   Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      //   "Content-Type": "application/json",
      // },
    });
    const data = response.data.data[0];
    setContactInfo({
      phone: data.ContactNumber || "(902) 494-1811",
      email: data.ContactEmail || "asiabernabo@dal.ca",
    });
  } catch (error) {
    console.error("Error retrieving from Strapi backend:", error);
  }
};

fetchContactInfo();
}, [API_URL]);


  return (
    <div className="App secondary-background">
      <div className="contact-container">
        <section className="pt-10-h" id="contact-us"></section>
        <header className="header">
          <h1>Contact Us</h1>
        </header>
        <div className="small">We look forward to hearing from you!</div>
        <div className="contact-info-container px-10-w pb-5-w">
          <div className="contact-us-container">
            <div className="contact">
              <ContactForm />
            </div>
            <div className="information">
              <div className="contact-details">
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General Contact Info</h2>
                <div className="contacts">
                  <FaPhone className="icon" />
                  Phone: (902) 494-1811
                </div>
                <div className="contacts">
                  <FaEnvelope className="icon" />
                  Email: dca@dal.ca
                </div>
              </div>
              <div className="contact-details">
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Executive Contact Info</h2>
                <div className="contacts">
                  <FaUser className="icon" />
                  President: {contactInfo.executive}
                </div>
                <div className="contacts">
                  <FaEnvelope className="icon" />
                  Email: {contactInfo.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
