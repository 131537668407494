import React, { useState } from 'react';
import '../App.css';
import img from '../img/DCA-no-background.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

return (
  <>
    {/* Maintenance Message */}
    <div
      style={{
        color: "#eca53b", // Text color
        textAlign: "center", // Center text
        height: "40px", // Fixed height for the maintenance bar
        lineHeight: "40px", // Vertically center the text
        fontWeight: "bold", // Bold text
        fontSize: "14px", // Font size
        position: "fixed", // Keeps the bar at the top
        top: "0", // Top of viewport
        width: "100%", // Full width
        zIndex: "1000", // Above other elements
        backgroundColor: "var(--darkblackcolor)", // Background color for contrast
      }}
    >
      Our website is currently undergoing maintenance to improve your
      experience. We apologize for any inconvenience and appreciate your
      patience!
    </div>

    {/* Navbar */}
    <nav
      className="navbar-holder"
      style={{
        position: "fixed", // Fixed position
        top: "40px", // Matches the height of the maintenance bar
        width: "100%",
        zIndex: "999", // Below the maintenance bar
        backgroundColor: "var(--darkblackcolor)", // Match navbar and maintenance bar
      }}
    >
      <div className="logo-img">
        <img src={img} alt="DCA Logo" />
      </div>

      <div className={`nav-links ${isOpen ? "active" : ""}`}>
        <a href="/home#">Home</a>
        <a href="/home#about-us">About</a>
        <a href="/membership">Membership</a>
        <a href="/sponsors">Sponsors</a>
        <a href="/resources">Resources</a>
        <a href="/home#contact-us">Contact Us</a>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  </>
);
};

export default Navbar;




