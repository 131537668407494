import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Static data fallback in case Strapi is down or unavailable
const staticTeamMembers = [
  { name: 'John Doe', role: 'President', image: 'https://via.placeholder.com/150', order: 1 },
  { name: 'Jane Smith', role: 'Team Member', image: 'https://via.placeholder.com/150', order: 2 },
  { name: 'Mike Johnson', role: 'Team Member', image: 'https://via.placeholder.com/150', order: 3 },
  { name: 'Emily Davis', role: 'Team Member', image: 'https://via.placeholder.com/150', order: 4 },
  { name: 'Robert Brown', role: 'Team Member', image: 'https://via.placeholder.com/150', order: 5 },
  { name: 'Linda Wilson', role: 'Team Member', image: 'https://via.placeholder.com/150', order: 6 },
  { name: 'James Taylor', role: 'Team Member', image: 'https://via.placeholder.com/150', order: 7 },
];

const TeamStructure = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_URL_LOCAL;



  useEffect(() => {
  axios
    .get(`${API_URL}/api/team-members?populate=*`, {
      timeout: 10000,
    })
    .then((response) => {
      console.log("API Response:", response.data); // Log full response for debugging

      const membersData = response.data.data.map((member) => {
        const rawImageUrl = member.Image?.formats?.large?.url || member.Image?.formats?.medium?.url; // Use higher resolution format

        // Log the raw URL
        console.log("Raw Image URL for", member.Name || "No Name", ":", rawImageUrl);

        const cleanedImageUrl = rawImageUrl
          ? rawImageUrl.replace(/\n/g, "").trim() // Clean up newline and extra spaces
          : "https://via.placeholder.com/150"; // Fallback for missing images

        // Log the cleaned URL
        console.log("Cleaned Image URL for", member.Name || "No Name", ":", cleanedImageUrl);

        return {
          name: member.Name || "No Name",
          role: member.Role || "No Role",
          image: cleanedImageUrl,
          order: member.Order || 999,
        };
      });

      // Log processed data to verify
      console.log("Processed Members Data:", membersData);

      // Sort members based on the Order field
      const sortedMembers = membersData.sort((a, b) => a.order - b.order);

      setTeamMembers(sortedMembers); // Update state with team members
      setLoading(false); // Stop loading spinner
    })
    .catch((error) => {
      console.error("Error fetching team members from Strapi:", error);
      setTeamMembers([]); // Fallback to an empty array
      setLoading(false);
    });
}, [API_URL]);

    
    
    
    


  if (loading) {
    return <div>Loading...</div>;
  }

  // Separate the first member from the rest if needed for different styling
  const firstMember = teamMembers[0];
  const remainingMembers = teamMembers.slice(1);

  return (
    <div className="team-container px-10-w pb-5-w center-items secondary-background">
      <section className="pt-10-h" id="our-team secondary-background"></section>
      {/* Header section */}
      <header className="header">
        <h1>Our Team</h1>
      </header>

      <main className="main-team-content center-items">
        <div className="team-box">
          {/* Display first member prominently */}
          {firstMember && (
            <div className="president team-member">
              <img src={firstMember.image} alt={firstMember.name} className="member-image" />
              <h3>{firstMember.name}</h3>
              <p>{firstMember.role}</p>
            </div>
          )}

          {/* Display remaining members */}
          <div className="members-row">
            {remainingMembers.map((member, index) => (
              <div key={index} className="team-member text-center">
                <img src={member.image} alt={member.name} className="member-image" />
                <h4>{member.name}</h4>
                <p>{member.role}</p>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default TeamStructure;
